(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.person.controller:PersonCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.persons.person')
  .controller('PersonCtrl', PersonCtrl);

  function PersonCtrl() {
    var vm = this;
    vm.ctrlName = 'PersonCtrl';
    vm.currentNavItem = 'informacio';
  }
}());
